import React, {useState, useEffect} from "react"
import styled from "styled-components"
import Taggy from  'components/common/taggy'


const StyledContainer = styled.form`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  line-height: 2rem;
  font-size: 1rem;
  white-space: pre-wrap;
`

const Parsing = (props) => {
  const spans = props.jobParsing.entities.map(ent => ({type: ent.label, start: ent.start, end: ent.end}))
  const text = props.jobParsing.text

  const ents = [
    { type: 'first_name', color: {h: 303, s: 98.1, l: 42.2, a: 1}},
    { type: 'last_name', color: {h: 191, s: 95, l: 23, a: 1}},
    { type: 'date', color: {h: 229, s: 51.9, l: 54.3, a: 1}},
    { type: 'duration', color: {h: 321, s: 70.9, l: 67.6, a: 1}},
    { type: 'location', color: {h: 94, s: 48.6, l: 50.4, a: 1}},
    { type: 'company', color: {h: 71, s: 59.5, l: 51.6, a: 1}},
    { type: 'job_title', color: {h: 52, s: 62.5, l: 43.9, a: 1}},
    { type: 'task', color: {h: 80, s: 75.5, l: 53.1, a: 1}},
    { type: 'school', color: {h: 282, s: 98.6, l: 71, a: 1}},
    { type: 'education_title', color: {h: 291, s: 98.9, l: 36.1, a: 1}},
    { type: 'course', color: {h: 274, s: 64.9, l: 19, a: 1}},
    { type: 'skill_hard', color: {h: 203, s: 79.9, l: 61, a: 1}},
    { type: 'skill_soft', color: {h: 200, s: 6, l: 80.4, a: 1}},
    { type: 'certification', color: {h: 285, s: 55.7, l: 53.9, a: 1}},
    { type: 'language', color: {h: 168, s: 82, l: 52.2, a: 1}},
    { type: 'interest', color: {h: 272, s: 32.7, l: 43.7, a: 1}},
    { type: 'email', color: {h: 351, s: 70.3, l: 61.8, a: 1}},
    { type: 'phone', color:  {h: 90, s: 23.7, l: 53.7, a: 1}},
    { type: 'url', color: {h: 204, s: 93.9, l: 44.9, a: 1}}
  ]
  const taggy = (<Taggy text={text} spans={spans} ents={ents} />)

  return (
    <StyledContainer color="#9F7AEA">
      {taggy}
    </StyledContainer>
  )
}


export default Parsing
