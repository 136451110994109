import React from "react"
import styled from "styled-components"
import Form from "./form"
import Result from "./result"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`


const Parsing = (props) => {
  return (
    <StyledContainer
      color="#9F7AEA"
    >
      <Form
        postJobParsing={props.postJobParsing}
        response={props.response}
        params={props.queryParams}
        jobParsing={props.jobParsing} 
      />
      {props.queryParams?.key ?
        (
          <Result 
            getJobParsing={props.getJobParsing}
            jobParsing={props.jobParsing}
            jobKey={props.queryParams.key}
            email={props.queryParams.email}
          />
        )
        :
        (
          ''
        )
      }
    </StyledContainer>
  )
}


export default Parsing